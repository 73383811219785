<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { meta } from 'tinro';
  import {
    Button,
    Icon,
    List,
    ListItem,
    Menu,
    Select,
    Snackbar,
    TextField,
  } from 'svelte-materialify';
  import { mdiChevronDown, mdiContentCopy, mdiMagnify } from '@mdi/js';
  import { Grid } from 'ag-grid-community';
  import 'ag-grid-enterprise';
  import 'ag-grid-enterprise/styles/ag-grid.css';
  import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
  import { LicenseManager } from 'ag-grid-enterprise';
  import { apiFetch } from '../services/network';
  import { _ } from '../services/i18n';
  import { CopyUrlToClipboard } from './CopyURL.svelte';

  const licenseKey =
    'Using_this_{AG_Grid}_Enterprise_key_{AG-067356}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Orula_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Fortress}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Fortress}_need_to_be_licensed___{Fortress}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{23_November_2025}____[v3]_[01]_MTc2Mzg1NjAwMDAwMA==e0447405dfac0afb78da319ffad78b94';
  LicenseManager.setLicenseKey(licenseKey);

  export let rowSelectable = false;

  /**
   * @property rowActions[]: {Array, <Object>}
   * @description Array of objects that the button renderer uses to create the buttons that go in each table such as a view patent button.
   * @property rowActions.type: <button|checkbox>
   * @property rowActions.enabledFunction: function
   * @property rowActions.href: string
   * @property rowActions.title: string
   * @property rowActions.path: <icon|string>
   * @property rowActions.onClickEvent: string
   * @description rowActions.onClickEvent is a string that is the name of a function. String will be dispatched as an event when the button is cicked.
   * @property rowActions.elementId: string
   * @property rowActions.value: Object
   * @description rowActions.value is a value for the checkbox to bind to if checkbox is choden for type.
   */
  export let rowActions = [];
  /**
   * @property globalActions[]: {Array, <Object>}
   * @description Array of objects same as rowActions but buttons are created outside of the table.
   * @property globalActions.type: <button|checkbox>
   * @property globalActions.enabledFunction: function
   * @property globalActions.href: string
   * @property globalActions.title: string
   * @property globalActions.path: <icon|string>
   * @property globalActions.onClickEvent: string
   * @description globalActions.onClickEvent is a string that is the name of a function. String will be dispatched as an event when the button is cicked.
   * @property globalActions.elementId: string
   * @property globalActions.value: Object
   * @description globalActions.value is a value for the checkbox to bind to if checkbox is choden for type.
   */
  export let globalActions = [];
  /**
   * @property linkActions[]: {Array, <Object>}
   * @description Array of objects same as rowActions but for links not buttons. Decides if/which columns should have links in their data to ip.
   * @property linkActions.onClickEvent: string
   * @property linkActions.column
   * @property linkActions.enabledFunction: function
   * @property linkActions.href: string
   * @property linkActions.title: string
   * @property linkActions.elementId: string
   */
  export let linkActions;
  /**
   * @property rowData: {Array, <Object>}
   * @description Array of objects that the data table displays. Column names must match those in column defs. Columns depend on the table.
   * @description Ex let rowData = [{id: 1, name: patent1, country, country1}{id: 2, name: otherName, country: country2}]
   */

  export let rowData = [];
  /**
   * @property columnDefs: {Array, <Object>}
   * @description Array of objects/object filled with objects. See documentation at https://www.ag-grid.com/javascript-data-grid/column-definitions/
   */
  export let columnDefs = [];
  /**
   * @property table: string
   * @description Name of the table the table state is to be associated with. For loading saved changes to a table.
   * @type string  ["patent", "patent-infamily", "patent-addtogroup", "patent-group"]
   */
  export let tableName;
  export let gridId = tableName;

  const route = meta();

  let showSnackBar = false;
  const snackBarText = $_('ip.link-copied');

  const dispatch = createEventDispatcher();

  let gridDiv;
  onMount(() => {
    new Grid(gridDiv, gridOptions);
  });

  let searchTerm = '';

  /**
   * @property defaultState: {Array, <Object>}
   * @description Default table that is loaded with no saved column changes.
   */
  let defaultState = [];
  /**
   * @property gridOptions: {Array, <Object>}
   * @description Array of objects, settings ag-grid uses to set up the table. See documentation at https://www.ag-grid.com/javascript-data-grid/grid-interface/#grid-options
   */
  export let numRows = 10;

  let gridOptions = {
    rowSelection: rowSelectable ? 'multiple' : undefined,
    rowMultiSelectWithClick: rowSelectable ? true : undefined,
    columnDefs,
    defaultColDef: {
      maxWidth: 800,
      filterParams: {
        newRowsAction: 'keep',
      },
    },
    suppressColumnMoveAnimation: true,
    suppressColumnVirtualisation: true,
    domLayout: 'autoHeight',
    rowData,
    pagination: true,
    paginationPageSize: numRows,
    suppressRowHoverHighlight: true,
    unSortIcon: true,
    getRowId: (row) => row.data.id,
    components: { linkRenderer: createLinkSpan },
    onGridReady: setUpTable,
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivots: true,
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
          minWidth: 225,
          maxWidth: 225,
          width: 225,
        },
      ],
      position: 'right',
      closedByDefault: true,
    },
    getMainMenuItems,
    getContextMenuItems,
    popupParent: document.querySelector('body'),
    onGridColumnsChanged() {
      gridColumnsResize();
    },
    onGridSizeChanged() {
      gridColumnsResize();
    },
    onRowDataUpdated: () => {
      gridColumnsResize();
    },
    onColumnVisible: () => {
      gridColumnsResize();
    },
    onRowSelected: (params) => {
      dispatch('rowSelected', { ...params.data, selected: params.node.selected });
    },
    onCellClicked: (params) => {
      if (params.column.colId === 'buttons') {
        params.api.contextMenuFactory.showMenu(
          params.node,
          params.column,
          params.value,
          params.event
        );
      }
    },
  };
  $: {
    if (searchTerm === '' && gridOptions.api) {
      gridOptions.api.setQuickFilter(searchTerm);
    }
  }

  function getMainMenuItems(params) {
    const menuItems = params.defaultItems.slice(0);
    menuItems[6] = {
      name: $_('datatable.resetColumns'),
      action() {
        resetTable();
      },
    };
    menuItems.push({
      name: $_('datatable.saveColumns'),
      action() {
        saveTable();
      },
    });
    menuItems.splice(2, 2);
    return menuItems;
  }

  // Customize the context menu
  function getContextMenuItems(params) {
    if (params.column.colId === 'buttons') {
      const result = [];
      for (const rowAction of rowActions) {
        if (rowAction.type === 'button') {
          result.push({
            name: rowAction.title,
            action: () => {
              dispatch(rowAction.onClickEvent, {
                id: params.node.data.id,
                action: rowAction,
                data: params.node.data,
              });
            },
            disabled: !rowAction.enabledFunction(params.node.data),
            cssClasses: rowAction.cssClasses,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" style="font-size:large; width:1em; margin-top: 3px;"><path d="${rowAction.path}" /></svg>`,
          });
        }
        if (rowAction.type === 'copyLinkButton') {
          result.push({
            name: $_('ip.copy-link-to-clipboard'),
            action: async () => {
              const path = `${route.url}/${params.node.data.id}`;
              await CopyUrlToClipboard(path);
              showSnackBar = true; // show status message to user
            },
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" style="font-size:large; width:1em; margin-top: 3px;"><path d="${mdiContentCopy}" /></svg>`,
          });
        }
      }

      return result;
    }

    return [
      {
        name: $_('ip.copy-link-to-clipboard'),
        action: async () => {
          const path = `${$route.url}/${params.node.data.id}`;
          await CopyUrlToClipboard(path);
          showSnackBar = true; // show status message to user
        },
        tooltip: $_('ip.copy-link-to-clipboard-tooltip'),
        icon: '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
        disabled: params.column.colDef.cellRenderer !== 'linkRenderer',
      },
      'copy',
      'copyWithHeaders',
      'separator',
      'export',
    ];
  }

  async function setUpTable() {
    // customize the context menu
    const iconTooltips = {
      '.ag-icon-none': 'Unsorted',
      '.ag-icon-asc': 'Sort ascending',
      '.ag-icon-desc': 'Sort descending',
      '.ag-icon-menu': 'Menu',
    };
    for (const item of Object.keys(iconTooltips)) {
      const elements = document.querySelectorAll(item);
      for (const element of elements) {
        element.setAttribute('title', iconTooltips[item]);
      }
    }
    // load any saved table state
    defaultState = gridOptions.columnApi.getColumnState();
    const url = `/api/user-settings/tableState/${tableName}`;
    const response = await apiFetch(url, 'GET');
    let state = defaultState;
    if (response.status === 200) {
      const signinReply = await response.json();
      state = JSON.parse(signinReply.state);
    }
    gridOptions.columnApi.applyColumnState({ state, applyOrder: true });
  }

  async function saveTable() {
    const state = JSON.stringify(gridOptions.columnApi.getColumnState());
    const url = `/api/user-settings/tableState`;
    const request = {
      state,
      table: tableName,
    };
    await apiFetch(url, 'POST', request);
  }

  async function resetTable() {
    gridOptions.columnApi.applyColumnState({ state: defaultState, applyOrder: true });
    const state = JSON.stringify(gridOptions.columnApi.getColumnState());
    const url = `/api/user-settings/tableState`;
    const request = {
      state,
      table: tableName,
    };
    await apiFetch(url, 'POST', request);
  }

  function changeNumRows(event) {
    numRows = event.detail;
    gridOptions.api.paginationSetPageSize(Number(numRows));
  }

  function findItemById(id) {
    return rowData.find((item) => item.id === Number(id));
  }

  function itemsSearch() {
    gridOptions.api.setQuickFilter(searchTerm);
  }

  // creted by Quinn to make the button I added make a post request.
  async function report() {
    const url = `/api/report/${tableName}`;
    const element = document.createElement('a');
    element.href = url;
    element.setAttribute('download', '');
    element.setAttribute('tinro-ignore', '');
    element.click();
  }

  function createLinkSpan(params) {
    if (!linkActions) {
      return params.data.name;
    }
    for (const linkAction of linkActions) {
      const element = document.createElement('a');
      element.innerHTML = linkAction.getNameFunction
        ? linkAction.getNameFunction(params.data)
        : params.data.name;
      let enabled = false;
      if (linkAction.enabledFunction) {
        enabled = linkAction.enabledFunction(params.data);
      }
      if (enabled) {
        element.title = linkAction.title;
        element.style.textDecoration = 'underline';
        element.style.color = '#1a76d2';
        element.style.cursor = 'pointer';
        element.addEventListener('click', () => {
          dispatch(linkAction.onClickEvent, {
            id: params.data.id,
            action: linkAction,
            data: params.data,
          });
        });
      } else {
        element.style.color = '#808080';
      }
      return element;
    }
  }

  function generateGlobalEvent(event) {
    const button = event.currentTarget;
    let index = 0;
    let eventElement;
    if (button.title.includes('-')) {
      const titles = button.title.split('-');
      const parentTitle = titles[0];
      for (let i = 0; i < globalActions.length; i++) {
        if (globalActions[i].title === parentTitle) {
          for (let j = 0; j < globalActions[i].dropdownActions.length; j++) {
            if (globalActions[i].dropdownActions[j].title === titles[1]) {
              const eventDetail = {
                api: gridOptions.api,
                action: globalActions[i].dropdownActions[j].onClickEvent,
                href: globalActions[i].href,
              };
              const event = new CustomEvent(globalActions[i].dropdownActions[j].onClickEvent, {
                detail: eventDetail,
              });
              if (!eventElement) {
                eventElement = document.getElementById(globalActions[index].elementId);
              }
              eventElement.dispatchEvent(event);
            }
          }
        }
      }
    } else {
      for (const [i, globalAction] of globalActions.entries()) {
        if (globalAction.title === button.title) {
          index = i;
        }
      }
      const eventDetail = {
        api: gridOptions.api,
        action: globalActions[index].onClickEvent,
        href: globalActions[index].href,
      };
      const event = new CustomEvent(globalActions[index].onClickEvent, { detail: eventDetail });
      if (!eventElement) {
        eventElement = document.getElementById(globalActions[index].elementId);
      }
      eventElement.dispatchEvent(event);
    }
  }

  export function addNewRows(rowItems) {
    gridOptions.api.applyTransaction({ add: rowItems });
    gridOptions.api.redrawRows();
    return rowData;
  }

  export function updateRow(rowId) {
    const update = [];
    for (const rowDatum of rowData) {
      if (rowDatum.id === rowId) {
        update.push(rowDatum);
      }
    }
    gridOptions.api.applyTransaction({ update });
    gridOptions.api.redrawRows();
    return rowData;
  }

  export function deleteRows(rowId) {
    const remove = [];
    for (const rowDatum of rowData) {
      if (rowDatum.id === rowId) {
        remove.push(rowDatum);
      }
    }
    gridOptions.api.applyTransaction({ remove });
    gridOptions.api.redrawRows();
    return rowData;
  }

  function gridColumnsResize() {
    gridOptions.columnApi.autoSizeAllColumns();

    if (!gridDiv) {
      console.warn('Unable to resize columns, gridContainer ref is not provided');

      return;
    }

    const isColumnResizable = (colDef) => colDef.getColDef().resizable && colDef.visible;
    const columns = gridOptions.columnApi
      .getAllGridColumns()
      .filter((column) => isColumnResizable(column));
    if (columns.length === 0) {
      return;
    }

    // We gotta calculate the width of right stickied columns
    const leftStickiedColumns = gridOptions.columnApi
      .getAllGridColumns()
      .filter((column) => column.getColDef().pinned === 'left');
    const leftStickiedColumnsWidth = leftStickiedColumns.reduce(
      (acc, column) => acc + column.actualWidth,
      0
    );

    const lastColumn = columns[columns.length - 1];
    const lastColumnLeft = lastColumn.getLeft();
    const lastColumnWidth = lastColumn.getActualWidth();
    const { width: gridWidth } = gridDiv.getBoundingClientRect();
    const gridSpaceLeftToFill = Math.max(
      0,
      gridWidth - (lastColumnLeft + lastColumnWidth) - 33 - leftStickiedColumnsWidth
    );

    if (gridSpaceLeftToFill === 0) {
      return;
    }
    const additionalSpaceForEachColumn = gridSpaceLeftToFill / columns.length;
    const columnState = gridOptions.columnApi.getColumnState();

    for (const column of columnState) {
      const skipResizeForColumn = !columns.some((col) => col.getColId() === column.colId);

      if (!skipResizeForColumn) {
        column.width += additionalSpaceForEachColumn;
      }
    }
    gridOptions.columnApi.applyColumnState({ state: columnState });
  }

  const items = [10, 20, 40, 80];
</script>

<div class="d-flex align-center justify-space-between pt-4 pb-4 flex-wrap">
  <!--  -->
  <div class="d-flex align-center flex-wrap flex-md-nowrap">
    {#each globalActions as action}
      <div class="pl-2">
        {#if action.dropdown}
          <Menu>
            <div slot="activator">
              <Button
                id={action.id}
                disabled={action.enabled}
                class={action.enabled ? 'secondary-color grey-text' : 'secondary-color'}
                text
                title={action.title}
                size="small"
              >
                <Icon path={action.path} />
                {#if action.text}
                  {action.text}
                {/if}
                <Icon path={mdiChevronDown} />
              </Button>
            </div>
            <List disabled={action.enabled} dense>
              {#each action.dropdownActions as dropDownAction}
                <Button
                  disabled={dropDownAction.enabled}
                  on:click={generateGlobalEvent}
                  style={'width: 100%'}
                  text
                  title={`${action.title}-${dropDownAction.title}`}
                >
                  <ListItem>
                    {dropDownAction.text}
                  </ListItem>
                </Button>
              {/each}
            </List>
          </Menu>
        {:else}
          <Button
            id={action.id}
            disabled={action.enabled}
            class={action.enabled ? 'secondary-color grey-text' : 'secondary-color'}
            on:click={generateGlobalEvent}
            text
            title={action.title}
            fab
            size="small"
          >
            <Icon path={action.path} />
          </Button>
        {/if}
      </div>
    {/each}
    <TextField
      bind:value={searchTerm}
      on:keyup={itemsSearch}
      style="max-width: 400px;"
      class="pl-4 mb-0"
      color={'blue'}
      clearable
      outlined
      rounded
      dense
    >
      <div slot="prepend">
        <Icon path={mdiMagnify} />
      </div>
      {$_('groups-and-items.search')}
    </TextField>
  </div>

  <Select outlined dense {items} bind:value={numRows} on:change={changeNumRows} class="ml-2">
    {$_('datatable.slider')}
  </Select>
</div>
<div bind:this={gridDiv} id={gridId} class="ag-theme-alpine" on:gridReady={setUpTable} on:rowData />

<Snackbar class="flex-column" bind:active={showSnackBar} timeout={2000} top right>
  {snackBarText}
</Snackbar>

<style>
  :global(.ag-pinned-left-cols-container, .ag-pinned-left-header) {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    z-index: 1;
  }
  .ag-theme-alpine {
    height: 100%;
    width: 100%;
  }
</style>
